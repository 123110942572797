import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import routes from "../../routes"
import Input from "../../ui/Input/Input"
import Layout from "../../ui/Layout/Layout"
import { SignInData, signInAsync } from "../../Auth"
import ErrorMessage from "../../ui/ErrorMessage/ErrorMessage"
import { UserContext } from "../../context/User"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email address"),
  password: yup.string().required("Please enter your password")
})

const Form: React.FC = () => {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SignInData>({ resolver: yupResolver(validationSchema) })
  const { user } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (user) {
      history.push({
        pathname: routes.summary,
        search: history.location.search
      })
    }
  }, [user])

  const onSubmit = async (data: SignInData) => {
    setIsLoading(true)
    setError(null)

    try {
      await signInAsync(data)
    } catch (e) {
      setError(e)
    }

    setIsLoading(false)
  }

  return (
    <Layout
      title="Sign in"
      buttonText="Login"
      onClick={handleSubmit(onSubmit)}
      loading={isLoading}
    >
      <Input
        label="Email"
        register={register("email")}
        type="text"
        iconName="User"
        placeholder="Enter your email"
        error={errors?.email}
      />
      <Input
        label="Password"
        register={register("password")}
        type="password"
        iconName="Lock"
        placeholder="Enter your password"
        error={errors?.password}
      />
      <ErrorMessage error={error} />
    </Layout>
  )
}

export default Form
