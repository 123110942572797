import React, { useContext } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"

import { Form, Summary, Success } from "./components"
import PrivateRoute from "./components/PrivateRoute"
import routes from "./components/routes"
import Loading from "./components/ui/Loading/Loading"
import styles from "./App.module.scss"
import logo from "./assets/WadzPay-Logo.png"
import { UserContext, UserContextProvider } from "./components/context/User"

const Routes: React.FC = () => {
  const { user, isLoading } = useContext(UserContext)

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <img src={logo} alt="Logo" width={300} className={styles.logo} />
        <Loading size={40} />
      </div>
    )
  }

  return (
    <Router>
      <Switch>
        <Route exact path={routes.signIn}>
          <Form />
        </Route>
        <PrivateRoute path={routes.summary} isAuthenticated={!!user}>
          <Summary />
        </PrivateRoute>
        <PrivateRoute path={routes.success} isAuthenticated={!!user}>
          <Success />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Routes />
      </UserContextProvider>
    </QueryClientProvider>
  )
}

export default App
