enum ROUTE {
  SIGN_IN = "signIn",
  SUMMARY = "summary",
  SUCCESS = "success"
}

const routes: { [key in ROUTE]: string } = {
  signIn: "/",
  summary: "/summary",
  success: "/success"
}

export default routes
